import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthUseCaseService } from '../../auth/application/auth-use-case.service';

export const headersAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const _authUseCaseService = inject(AuthUseCaseService);
  const token = _authUseCaseService.token();

  if (req.headers.get('skiptoken')) {
    const headers = new HttpHeaders();
    const newReq = req.clone({ headers });
    return next(newReq);
  }

  const authRequest = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  return next(authRequest);
};
