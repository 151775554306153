import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./ui/customers/customers.routes').then((m) => m.CUSTOMERS_ROUTES),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./ui/auth/auth.routes').then((m) => m.AUTH_ROUTES),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./ui/admin/admin.routes').then((m) => m.ADMIN_ROUTES),
  },
  {
    path: 'employees',
    loadChildren: () =>
      import('./ui/employees/employees.routes').then((m) => m.EMPLOYEES_ROUTES),
  },
];
