import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { finalize } from 'rxjs';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const _loaderService = inject(LoaderService);

  _loaderService.show();
  return next(req).pipe(finalize(() => _loaderService.hide()));
};
