import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { APPOINTMENT_PROVIDER } from './domain/admin/appointments/infrastructure/appointment-api.provider';
import { AUTH_PROVIDER } from './domain/auth/infrastructure/auth-api.provider';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CUSTOMER_PROVIDER } from './domain/admin/customers/infrastructure/customer.provider';
import { EMPLOYEE_PROVIDER } from './domain/admin/employees/infrastructure/employee-api.provider';
import { headersAuthInterceptor } from './domain/shared/interceptors/headers-auth.interceptor';
import { loaderInterceptor } from './domain/shared/interceptors/loader.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { SERVICE_PROVIDER } from './domain/admin/services/infrastructure/service-api.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([loaderInterceptor, headersAuthInterceptor])
    ),
    provideRouter(routes),
    provideZoneChangeDetection({ eventCoalescing: true }),
    ConfirmationService, // PrimeNG
    MessageService, // PrimeNG
    APPOINTMENT_PROVIDER,
    AUTH_PROVIDER,
    CUSTOMER_PROVIDER,
    EMPLOYEE_PROVIDER,
    SERVICE_PROVIDER,
  ],
};
